import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Location from "../components/Location"
import Section from "../components/Section"
import {
  PrimaryButton,
  Service,
  TextContent,
  ThreeColumnGrid,
} from '../styles/utility'

const ServicesPage = () => (
  <Layout>
    <SEO title="Dental Services Bella Vista &amp; Norwest" noFollow={true} description="Are you looking for dental treatment in Bella Vista or Norwest? We offer a variety of general and cosmetic dentistry treatments. Book an appointment today." />
    <Section pageTitle="Dental Services In Bella Vista &amp; Norwest">
        <TextContent>
            <p>The services available to patients at Norwest Dental Care are extensive and innovative. We pride ourselves on our ability to perform a vast array of Cosmetic Dentistry, General Dentistry and Gum Therapy procedures.</p>
            <p>All treatments are conducted using the latest technology and the most up to date equipment. Sterilisation and hygiene is always at a maximum and the patient's oral well-being is our first priority.</p>
            <p>Strong emphasis is placed on patient comfort whilst all dental treatments are being carried out. The use of gas during some dental procedures, aids with any pain or discomfort that may be felt by the patient.</p>
            <p>Our Services can be placed into three general categories:</p>
        <ThreeColumnGrid>
          <Service>
            <StaticImage 
              src="../images/undraw_medical_research.png" 
              alt="General Dentistry"
              placeholder="blurred"
              height={175} 
            />
            <div>
              <h3>General Dentistry</h3>
            </div>
            <PrimaryButton href="/services/general-dentistry/">Learn More</PrimaryButton>
          </Service> 
          <Service>
            <StaticImage 
              src="../images/undraw_wash_hands.png" 
              alt="General Dentistry"           
              placeholder="blurred"
              height={175} 
            />
            <div>
              <h3>Gum Therapy</h3>
            </div>
            <PrimaryButton href="/services/gum-therapy/">Learn More</PrimaryButton>
          </Service>
          <Service>
            <StaticImage 
              src="../images/undraw_certification.png" 
              alt="General Dentistry"
              placeholder="blurred"
              objectPosition="50% 50%"
              height={175} 
            />
            <div>
              <h3>Cosmetic Dentistry</h3>
            </div>
            <PrimaryButton href="/services/cosmetic-dentistry/">Learn More</PrimaryButton>
          </Service>
        </ThreeColumnGrid>
            <p>We recommend that you visit us every six months for a routine examination and clean. In between these consultations if you experience any oral pain or discomfort, please make an appointment with us straight away; so that we can fix the problem early on.</p>
            <p>Oral Health is a key element in attaining overall well-being. All the services that we provide at Norwest Dental Care are designed to help improve and/or repair your teeth and give you the beautiful smile you always dreamed of.</p>
        </TextContent>
    </Section>
    <Section>
      <Location />
    </Section>
  </Layout>
)

export default ServicesPage
